<template>
  <b-container>
    <l-map
      v-if="showMap && items.length !== 0"
      :zoom="Number(zoom)"
      :center="center"
      :options="mapOptions"
      style="height: 700px"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer :url="url" />
      <div v-for="(item, index) in items" :key="index">
        <l-marker v-if="item.Latitude" :lat-lng="JSON.parse(item.coordinates)">
          <l-popup>
            <p>{{ item["Outpost Name"] }}</p>
          </l-popup>
        </l-marker>
      </div>
    </l-map>
  </b-container>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
  },
  props: ["items", "directory"],
  computed: {
    center() {
      return latLng(
        this.$props.directory.mapCenterLat,
        this.$props.directory.mapCenterLong
      );
    },
    zoom() {
      return this.$props.directory.mapZoom;
    },
  },
  data() {
    return {
      // zoom: 7,
      // center: latLng(36.6983183, 127.0475732),
      url: "https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png",
      currentZoom: null,
      currentCenter: null,

      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
    };
  },
  methods: {
    calculateCoordinates(item) {
      let c = JSON.parse(item.coordinates);
      return c;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
  },
};
</script>
